import isWithinInterval from 'date-fns/isWithinInterval';
import { ApplicationType } from '../../types/ApplicationType';
import { ApplicationSurvey } from '../../types/application';
import { FarmGrowingSeasons_growingSeasons } from '../../services/farms/types/FarmGrowingSeasons.gql';

type Params = {
  applicationId: string;
  farmId: string;
  openApplication: ApplicationType;
  survey: ApplicationSurvey;
  seasons: FarmGrowingSeasons_growingSeasons[];
  appUrl: string;
};

function findActiveSeason(
  seasons: FarmGrowingSeasons_growingSeasons[],
  surveyDate: string,
) {
  return seasons.find((season: FarmGrowingSeasons_growingSeasons) =>
    isWithinInterval(new Date(surveyDate), {
      start: new Date(season.startDate),
      end: new Date(season.endDate),
    }),
  );
}

function getSurveyLink({
  applicationId,
  appUrl,
  farmId,
  survey,
  seasons,
  openApplication,
}: Params) {
  const selectedSeason = findActiveSeason(seasons, survey.captured_at);
  let url = `${appUrl}/map/${farmId}/field/${survey.field_id}/application?applicationId=${applicationId}&surveyId=${survey.id}`;
  if (selectedSeason && openApplication)
    url += `&seasonId=${selectedSeason.id}&openApplication=${openApplication}`;
  return url;
}

export default getSurveyLink;
