import 'stop-runaway-react-effects/hijack';
import React from 'react';
import ReactDOM from 'react-dom';
import 'fix-date';
import * as Sentry from '@sentry/react';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
  const environment = `${process.env.NODE_ENV}-${process.env.API_ENV}`;

  Sentry.init({
    dsn: 'https://5264c8177ea74d67a766a06488a82dfb@o365832.ingest.sentry.io/6071133',
    environment,
  });
}

/**
 * This is the root of our application. Only the App component should be used here
 *
 * If you need to register globally available Providers please do so in /App/containers/App in the AppWithProviders component
 */
ReactDOM.render(<App />, document.getElementById('main'));
