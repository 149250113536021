import { ApplicationType } from 'types/ApplicationType';

const applicationList: Record<
  string,
  { webappKey: ApplicationType; name: string }
> = {
  'application-nitrogen/analysis-mean_size': {
    webappKey: 'nitrogenMeanSizeApplication',
    name: 'Nitrogen (Mean size) (Contoured)',
  },
};

export default applicationList;
