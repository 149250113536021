import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

type Props = {
  className: string;
  children: React.ReactNode;
};

const useStyles = createUseStyles(() => ({
  root: {
    display: 'block',
  },
}));

export default ({ className, children }: Props) => {
  const classes = useStyles();
  return (
    <table className={classNames(classes.root, className)}>{children}</table>
  );
};
