import { useQuery, UseQueryResult } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { Application } from '../../types/application';

export default (farmId: string): UseQueryResult<Application[], Error> => {
  const config = useConfig();
  return useQuery(
    ['applicationList', farmId],
    () =>
      get(
        config.gqlGatewayUrl,
        `/api/v1/products/?classes=application&farm=${farmId}&page_size=300`,
      ),
    { enabled: Boolean(farmId) },
  );
};
