import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import baseStyles from '@hummingbirdtechgroup/crips-ui/dist/atoms/styles/baseStyles';
import styles from '../../constants/styles';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const useStyles = createUseStyles({
  ...baseStyles,
  root: {
    ...styles.cell,
    paddingTop: 16,
    paddingBottom: 16,
  },
});

export default ({ children, className }: Props) => {
  const classes = useStyles();
  return <td className={classNames(classes.root, className)}>{children}</td>;
};
