import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  root: {
    display: 'block',
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <thead className={classes.root}>{children}</thead>;
};
