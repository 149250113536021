export type ApplicationSurvey = {
  id: string;
  field_id: string;
  captured_at: string;
};

export enum ApplicationStatus {
  completed = 'Completed',
  pending = 'Pending',
  failed = 'Failed',
}

export type Application = {
  id: string;
  product_type_name: string;
  created_at: string;
  enabled: boolean;
  status: ApplicationStatus;
  survey: ApplicationSurvey;
};
