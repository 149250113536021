import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  GlobalLayout,
  NavIconExample,
  IntercomIcon,
} from '@hummingbirdtechgroup/crips-ui';
import { Colours } from '@hummingbirdtechgroup/crips-ui';
import { useUser } from '@hummingbirdtechgroup/wings-auth';

const useStyles = createUseStyles(() => ({
  root: {
    backgroundColor: Colours.inputBackground,
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const [user] = useUser();

  const optionsList = [
    {
      iconElement: NavIconExample,
      optionName: 'Flight',
      to: 'flight',
    },
    {
      iconElement: NavIconExample,
      optionName: 'Logout',
      to: {
        pathname: 'https://accounts.hummingbirdtech.com/logout',
      },
    },
    {
      iconElement: IntercomIcon,
      optionName: 'Support',
      to: 'support',
      clickHandler: () => console.log('intercom called'),
    },
  ];
  return (
    <div className={classes.root}>
      <GlobalLayout optionsList={optionsList} accountName={user?.name}>
        {children}
      </GlobalLayout>
    </div>
  );
};
