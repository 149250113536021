import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from '../../constants/styles';

const useStyles = createUseStyles(() => ({
  root: {
    ...styles.card,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: 16,
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <tr className={classes.root}>{children}</tr>;
};
