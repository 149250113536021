import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { FontWeights } from '@hummingbirdtechgroup/crips-ui';
import mediaQueries from '../mediaQueries';

const cell = {
  boxSizing: 'border-box',
  flexGrow: 1,
  flexBasis: 0,
  width: 'auto',
  fontFamily: fonts.helveticaNeue,
  fontWeight: FontWeights.Regular,
  paddingLeft: 24,
  paddingRight: 24,
  fontSize: '1.4rem',
  lineHeight: '2rem',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'left',
  [mediaQueries.desktop]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [mediaQueries.bigDesktop]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
};

export default cell;
