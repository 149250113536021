import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colours } from '@hummingbirdtechgroup/crips-ui';

const useStyles = createUseStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <tr className={classes.root}>{children}</tr>;
};
