import React from 'react';
import { UserProvider, useUser } from '@hummingbirdtechgroup/wings-auth';
import { useIntercomMessenger } from '@hummingbirdtechgroup/wings-intercom';
import { SentryScope } from 'services/sentry';
import { QueryClientProvider } from 'react-query';
import { ApolloProvider } from '@apollo/client';
import { browserHistory, Router } from '@hummingbirdtechgroup/wings-router';
import {
  GoogleAnalytics,
  useGoogleAnalyticsEvent,
} from '@hummingbirdtechgroup/wings-ga';
import {
  ConfigProvider,
  AppEnvironment,
  envConfigs,
} from '@hummingbirdtechgroup/wings-config';
import AppRouter from './AppRouter';
import queryClient from './queryClient';
import client from './gqlClient';

function App() {
  const [user] = useUser();
  useIntercomMessenger({ user });
  useGoogleAnalyticsEvent();
  const { freshAnalyticsCode } =
    envConfigs[process.env.API_ENV as AppEnvironment];

  return (
    <>
      <AppRouter />
      {process.env.NODE_ENV === 'production' && (
        <>
          <SentryScope />
          <GoogleAnalytics gaTrackingCode={freshAnalyticsCode} />
        </>
      )}
    </>
  );
}

function AppWithProviders(): React.ReactElement {
  const { gqlGatewayUrl } = envConfigs[process.env.API_ENV as AppEnvironment];

  return (
    <ConfigProvider env={process.env.API_ENV as AppEnvironment}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client(gqlGatewayUrl)}>
          <UserProvider>
            <Router history={browserHistory as any}>
              <App />
            </Router>
          </UserProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default AppWithProviders;
