import React, { useMemo } from 'react';
import { get } from 'lodash-es';
import format from 'date-fns/format';
import { createUseStyles } from 'react-jss';
import {
  ApplicationButton,
  BodyRow,
  Cell,
  Controls,
  FarmsSelect,
  Header,
  HeaderRow,
  Message,
  Page,
  Ribbon,
  Table,
  TBody,
  THead,
} from 'components';
import { useSearchParams } from '@hummingbirdtechgroup/wings-router';
import { PageHeader } from '@hummingbirdtechgroup/crips-ui';
import {
  useFarmSeasonList,
  useFieldList,
  useGeneratedApplicationList,
} from 'services';
import applicationList from 'constants/applicationList';
import mediaQueries from 'constants/mediaQueries';
import { FarmFieldList_farm_fields } from 'services/fields/types/FarmFieldList.gql';
import { FarmGrowingSeasons_growingSeasons } from 'services/farms/types/FarmGrowingSeasons.gql';
import {
  Application,
  ApplicationStatus,
  ApplicationSurvey,
} from '../../types/application';

const DD_MM_YYYY = 'dd MMM yyyy';
const HEADER_HEIGHT = '224px';
const LOGO_HEIGHT = '224px';

const MOBILE_COLUMN_WIDTHS = {
  small: '50%',
  large: '100%',
};

const TABLET_COLUMN_WIDTHS = {
  small: '14%',
  large: '18%',
};

const DESKTOP_COLUMN_WIDTHS = {
  first: '14%',
  small: '12%',
  large: '13%',
  last: '16%',
};

const getStatusColour = (status: ApplicationStatus) => {
  switch (status) {
    case ApplicationStatus.pending: {
      return 'amber';
    }
    case ApplicationStatus.failed: {
      return 'red';
    }
    default: {
      return 'green';
    }
  }
};

type NameMap = Record<string, string>;

const useStyles = createUseStyles(() => ({
  loader: {
    height: `calc(100vh - ${HEADER_HEIGHT});`,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    maxHeight: LOGO_HEIGHT,
    maxWidth: 'auto',
    opacity: 0.5,
  },
  table: {
    marginTop: 8,
    [mediaQueries.desktop]: {
      marginTop: 32,
      marginRight: 24,
      marginLeft: 24,
    },
  },
  createdOnCell: {
    flexBasis: MOBILE_COLUMN_WIDTHS.small,
    maxWidth: MOBILE_COLUMN_WIDTHS.small,
    [mediaQueries.tablet]: {
      flexBasis: TABLET_COLUMN_WIDTHS.large,
      maxWidth: TABLET_COLUMN_WIDTHS.large,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.first,
      maxWidth: DESKTOP_COLUMN_WIDTHS.first,
    },
  },
  fieldCell: {
    display: 'none',
    [mediaQueries.tablet]: {
      display: 'block',
      flexBasis: TABLET_COLUMN_WIDTHS.small,
      maxWidth: TABLET_COLUMN_WIDTHS.small,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.small,
      maxWidth: DESKTOP_COLUMN_WIDTHS.small,
    },
  },
  zoneCell: {
    display: 'none',
    [mediaQueries.tablet]: {
      display: 'block',
      flexBasis: TABLET_COLUMN_WIDTHS.small,
      maxWidth: TABLET_COLUMN_WIDTHS.small,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.small,
      maxWidth: DESKTOP_COLUMN_WIDTHS.small,
    },
  },
  gridSizeCell: {
    display: 'none',
    [mediaQueries.tablet]: {
      display: 'block',
      flexBasis: TABLET_COLUMN_WIDTHS.large,
      maxWidth: TABLET_COLUMN_WIDTHS.large,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.large,
      maxWidth: DESKTOP_COLUMN_WIDTHS.large,
    },
  },
  statusCell: {
    flexBasis: MOBILE_COLUMN_WIDTHS.small,
    maxWidth: MOBILE_COLUMN_WIDTHS.small,
    textAlign: 'right',
    [mediaQueries.tablet]: {
      textAlign: 'left',
      flexBasis: TABLET_COLUMN_WIDTHS.large,
      maxWidth: TABLET_COLUMN_WIDTHS.large,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.small,
      maxWidth: DESKTOP_COLUMN_WIDTHS.small,
    },
  },
  flightDateCell: {
    display: 'none',
    [mediaQueries.tablet]: {
      display: 'block',
      flexBasis: TABLET_COLUMN_WIDTHS.large,
      maxWidth: TABLET_COLUMN_WIDTHS.large,
    },
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.large,
      maxWidth: DESKTOP_COLUMN_WIDTHS.large,
    },
  },
  summaryButtonCell: {
    flexBasis: MOBILE_COLUMN_WIDTHS.large,
    [mediaQueries.desktop]: {
      flexBasis: DESKTOP_COLUMN_WIDTHS.last,
    },
  },
}));

function getFieldNamesLookupTable(fields: FarmFieldList_farm_fields[]) {
  return () =>
    fields.reduce<NameMap>(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {},
    );
}

function checkApplicationAvailable() {
  return (application: Application) =>
    !!applicationList[application.product_type_name];
}

export default function Home(): React.ReactElement {
  const classes = useStyles();

  const [urlQueryState] = useSearchParams();
  const selectedFarm = urlQueryState?.farm;

  const { data: fieldsData, loading: loadingFields } =
    useFieldList(selectedFarm);

  const fields = get(
    fieldsData,
    'farm.fields',
    [],
  ) as FarmFieldList_farm_fields[];

  const { data: seasonsData } = useFarmSeasonList(selectedFarm);

  const seasons = get(
    seasonsData,
    'growingSeasons',
    [],
  ) as FarmGrowingSeasons_growingSeasons[];

  const fieldsNames: NameMap = useMemo(getFieldNamesLookupTable(fields), [
    fields,
  ]);

  const { data: applications = [], isLoading: loadingApplications } =
    useGeneratedApplicationList(selectedFarm);

  const filteredApplications = useMemo(
    () =>
      applications
        .filter(checkApplicationAvailable())
        .map(application => ({
          ...application,
          created_at: new Date(application.created_at),
        }))
        .sort((a, b) => b.created_at.getTime() - a.created_at.getTime()),
    [applications],
  );

  const isLoading = loadingFields || loadingApplications;

  return (
    <Page>
      <PageHeader>Applications</PageHeader>

      <Controls>
        <FarmsSelect />
        {null && <button disabled>Create new applications</button>}
      </Controls>

      <Table className={classes.table}>
        <THead>
          <HeaderRow>
            <Header className={classes.createdOnCell}>Created on</Header>
            <Header className={classes.fieldCell}>Field</Header>
            <Header className={classes.zoneCell}>Zone</Header>
            <Header className={classes.gridSizeCell}>Grid size</Header>
            <Header className={classes.statusCell}>Status</Header>
            <Header className={classes.flightDateCell}>Flight date</Header>
            <Header className={classes.summaryButtonCell} />
          </HeaderRow>
        </THead>
        <TBody>
          {filteredApplications.map(application => (
            <BodyRow key={application.id}>
              <Cell className={classes.createdOnCell}>
                {format(application.created_at, DD_MM_YYYY)}
              </Cell>
              <Cell className={classes.fieldCell}>
                {fieldsNames[application.survey.field_id]}
              </Cell>
              {/* zone name is always blank for now - rd 25/11/2021 */}
              <Cell className={classes.zoneCell} />
              <Cell className={classes.gridSizeCell}>2x2m Grid</Cell>
              <Cell className={classes.statusCell}>
                <Ribbon colour={getStatusColour(application.status)}>
                  {application.status}
                </Ribbon>
              </Cell>
              <Cell className={classes.flightDateCell}>
                {format(new Date(application.survey.captured_at), DD_MM_YYYY)}
              </Cell>
              <Cell className={classes.summaryButtonCell}>
                <ApplicationButton
                  application={application}
                  survey={application.survey as ApplicationSurvey}
                  openApplication={
                    applicationList[application.product_type_name]?.webappKey
                  }
                  farmId={selectedFarm}
                  seasons={seasons}
                >
                  Open Summary
                </ApplicationButton>
              </Cell>
            </BodyRow>
          ))}
        </TBody>
      </Table>

      {!selectedFarm && (
        <Message>
          No farm has been selected yet. Start by selecting one.
        </Message>
      )}
      {selectedFarm && !filteredApplications.length && !isLoading && (
        <Message>No items to show.</Message>
      )}
      {isLoading && <Message>Loading application data...</Message>}
    </Page>
  );
}
