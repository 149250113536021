import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colours } from '@hummingbirdtechgroup/crips-ui';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = createUseStyles(() => ({
  root: {
    borderRadius: 4,
    backgroundColor: Colours.white,
    marginTop: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 8,
    [mediaQueries.desktop]: {
      marginTop: 16,
      marginRight: 24,
      marginLeft: 24,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 16,
    },
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
