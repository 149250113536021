import React from 'react';
import { P, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { createUseStyles } from 'react-jss';
import mediaQueries from '../../constants/mediaQueries';
import styles from '../../constants/styles';

type Props = {
  children: React.ReactNode;
};

const useStyles = createUseStyles(() => ({
  root: {
    ...styles.card,
    marginTop: 16,
    padding: [16, 24],
    fontWeight: FontWeights.Regular,
    fontFamily: fonts.helveticaNeue,
    [mediaQueries.desktop]: {
      marginLeft: 24,
      marginRight: 24,
      paddingTop: 24,
      paddingBottom: 24,
    },
  },
}));

const Message = ({ children }: Props) => {
  const classes = useStyles();
  return <P className={classes.root}>{children}</P>;
};

export default Message;
