import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { get } from 'lodash-es';
import { useSearchParams } from '@hummingbirdtechgroup/wings-router';
import useGetUserFarms from 'services/farms/useGetUserFarms';
import { UserFarms_farms } from 'services/farms/types/UserFarms.gql';
import { P, Select, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = createUseStyles(() => ({
  root: {
    [mediaQueries.desktop]: {
      paddingTop: 8,
    },
  },
  loadingMessage: {
    paddingTop: 8,
    fontWeight: FontWeights.Regular,
    fontFamily: fonts.helveticaNeue,
  },
  select: {
    [mediaQueries.tablet]: {
      width: 272,
    },
  },
}));

const FarmSelect = () => {
  const classes = useStyles();

  const [urlQueryState, setQueryParams] = useSearchParams();

  const selectedFarm = urlQueryState?.farm;

  const { data: farmsData } = useGetUserFarms();
  const farms = get(farmsData, 'farms', []) as UserFarms_farms[];
  const farmOptions: any[] = useMemo(
    () =>
      farms.map(farm => ({
        value: farm.id.toString(),
        label: farm.name,
      })),
    [farms],
  );

  const [selectedFarmOption, setSelectedFarmOption] = useState<any>();

  useEffect(() => {
    const farmToAdd = farmOptions.find(
      (farm: any) => farm?.value === selectedFarm,
    );
    if (farmToAdd) {
      setSelectedFarmOption(farmToAdd);
    }
  }, [farmOptions, urlQueryState]);

  const handleFarmChange = (event: any) => {
    console.log('handleFarmChange', event.target.value);

    setQueryParams({ farm: event?.target?.value }, { merge: true });
  };

  return (
    <div className={classes.root}>
      {!farmOptions.length && <P className={classes.loadingMessage}>loading</P>}
      {farmOptions.length > 0 && (
        <form>
          <Select
            className={classes.select}
            labelText="Select a farm"
            onChange={handleFarmChange}
            options={farmOptions}
          />
        </form>
      )}
    </div>
  );
};

export default FarmSelect;
