import React from 'react';
import { createUseStyles } from 'react-jss';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { ActionAnchor, ActionButton } from '@hummingbirdtechgroup/crips-ui';
import { FarmGrowingSeasons_growingSeasons } from 'services/farms/types/FarmGrowingSeasons.gql';
import { ApplicationStatus, ApplicationSurvey } from 'types/application';
import { ApplicationType } from 'types/ApplicationType';
import getSurveyLink from './getSurveyLink';

const useStyles = createUseStyles(() => ({
  root: {
    width: '100%',
  },
}));

export type Application = {
  id: string;
  product_type_name: string;
  created_at: Date;
  enabled: boolean;
  status: ApplicationStatus;
  survey: ApplicationSurvey;
};

type Props = {
  application: Application;
  farmId: string;
  openApplication: ApplicationType;
  survey: ApplicationSurvey;
  seasons: FarmGrowingSeasons_growingSeasons[];
  children: React.ReactNode;
};

const ApplicationButton = ({
  application,
  farmId,
  openApplication,
  survey,
  seasons,
  children,
}: Props) => {
  const classes = useStyles();
  const { appUrl } = useConfig();

  const href = getSurveyLink({
    applicationId: application.id,
    appUrl,
    openApplication,
    farmId,
    survey,
    seasons,
  });

  return (
    <>
      {application.status === ApplicationStatus.completed ? (
        <ActionAnchor className={classes.root} href={href} target="_blank">
          {children}
        </ActionAnchor>
      ) : (
        <ActionButton className={classes.root} disabled>
          {children}
        </ActionButton>
      )}
    </>
  );
};

export default ApplicationButton;
