import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const COLOURS = {
  green: '#00AF00',
  amber: '#FFB100',
  red: '#B52525',
};

const BACKGROUND_COLOURS = {
  green: 'rgba(0, 175, 0, 0.1)',
  amber: 'rgba(255, 177, 0, 0.1)',
  red: 'rgba(181, 37, 37, 0.1)',
};

type Props = {
  colour: 'green' | 'amber' | 'red';
  children: React.ReactNode;
};

const useStyles = createUseStyles({
  root: {
    padding: [4, 8],
    display: 'inline-block',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  green: {
    color: COLOURS.green,
    backgroundColor: BACKGROUND_COLOURS.green,
  },
  amber: {
    color: COLOURS.amber,
    backgroundColor: BACKGROUND_COLOURS.amber,
  },
  red: {
    color: COLOURS.red,
    backgroundColor: BACKGROUND_COLOURS.red,
  },
});

const Ribbon = ({ colour, children }: Props) => {
  const classes = useStyles();
  return (
    <span className={classNames(classes.root, classes[colour])}>
      {children}
    </span>
  );
};

export default Ribbon;
