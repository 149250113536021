import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import {
  ADMIN,
  UPLOAD_PERMISSIONS_STRING,
  useUser,
} from '@hummingbirdtechgroup/wings-auth';
import { Navigation } from '@hummingbirdtechgroup/wings-components';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Home from './scenes/Home';
import { TrackVisitEvent } from './components';

function AppRouter(): React.ReactElement {
  const [user] = useUser();

  const { permissions, role } = user || { permissions: '', role: '' };
  const isAdmin = ADMIN.includes(role);
  const canUpload = UPLOAD_PERMISSIONS_STRING.includes(permissions as string);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/">
          {/* <Navigation isAdmin={isAdmin} canUpload={canUpload}> */}
          <TrackVisitEvent name="Applications">
            <Home />
          </TrackVisitEvent>
          {/* </Navigation> */}
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default AppRouter;
