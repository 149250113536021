import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = createUseStyles(() => ({
  root: {
    display: 'block',
    paddingBottom: 16,
    [mediaQueries.tablet]: {
      marginTop: 8,
    },
  },
}));

export default ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const classes = useStyles();
  return (
    <tbody className={classNames(classes.root, className)}>{children}</tbody>
  );
};
